import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'

import BlueBanner from '../../assets/images/blue-banner.svg'

const CSCBanner = () => {
  return (
    <CSCBannerWrapper
      style={{
        backgroundImage: `url(${BlueBanner})`,
        backgroundSize: 'cover',
        backgroundPosition: '100% 50%',
      }}
    >
      <div className="para">
        <h2>Create your account and get your score in as little as 1 minute</h2>
        <HomeButton
          height={BUTTON_SIZE.HEIGHT_L}
          padding={BUTTON_SIZE.PADDING_L}
          fontSize={BUTTON_SIZE.FONT_SIZE_L}
          background="#474ed3"
          backgroundHover="#3f45ba"
          color={BUTTON_COLOR.TEXT_COLOR_WHITE}
          margin="0"
          text="Register for FREE score"
          handleClick={() =>
            navigate('https://creditscore.societyone.com.au/create-account')
          }
        />
      </div>
    </CSCBannerWrapper>
  )
}

const CSCBannerWrapper = styled.div`
  width: 100%;
  padding: 40px;
  overflow: hidden;
  .para {
    padding: 120px 0;
    margin: 0 auto;
    max-width: 1232px;
  }
  h2 {
    max-width: 720px;
  }
  button {
    margin-top: 40px;
  }
`

export default CSCBanner

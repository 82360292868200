import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import HomeButton from '../UI/Buttons/HomeButton'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import InlineIcon from '../UI/InlineIcon'

import { FaChevronRight } from 'react-icons/fa'
import CreditScoreClubLogo from '../../assets/images/svg/CreditScoreClub.svg'

const CSCHero = () => {
  return (
    <ContentContainer>
      <CSCHeroWrapper className="hp-hero-grid w-layout-grid">
        <div className="col">
          <CreditScoreClubLogo style={{ width: 180 }} />
          <h1>
            Be in control of your{' '}
            <span className="purpleHeader">financial future.</span>
          </h1>
          <p>
            Get exclusive access to useful guides and tools that will help you
            learn and make more informed financial decisions.
          </p>
          <HomeButton
            height={BUTTON_SIZE.HEIGHT_L}
            padding={BUTTON_SIZE.PADDING_L}
            fontSize={BUTTON_SIZE.FONT_SIZE_L}
            background="linear-gradient(90deg,#5223d7,#7445f9);"
            color={BUTTON_COLOR.TEXT_COLOR_WHITE}
            text="Get My Score"
            margin="0"
            handleClick={() =>
              navigate('https://creditscore.societyone.com.au/create-account')
            }
          />
          <InlineIcon iconName="Person" smScreenJustifyContent="center">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://creditscore.societyone.com.au/login"
              className="sub-button"
            >
              Have an account? <span><strong>Sign in</strong></span>{' '}
            </a>
            <FaChevronRight style={{ width: 20 }} />
          </InlineIcon>
        </div>

        <div className="hero-img">
          <StaticImage
            src="../../assets/images/woman-phone-csc.png"
            alt="woman-with-phone"
            placeholder='none'
          />
        </div>
      </CSCHeroWrapper>
    </ContentContainer>
  )
}

const CSCHeroWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .col {
    max-width: 608px;
  }
  .purpleHeader {
    color: #5223d7;
  }
  .hero-img {
    flex-shrink: 0;
    margin-right: 50px;
    width: 40vw;
    max-width: 427px;
  }
  button {
    margin: 64px 0 40px;
  }
  .sub-button {
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    .hero-img {
      width: 60vw;
      margin-top: 80px;
    }
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export default CSCHero

import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import CSCHero from '../components/ForCreditScoreClub/CSCHero'
import CSCBenefits from '../components/ForCreditScoreClub/CSCBenefits'
import CreditScoreHowItWorks from '../components/ForCreditScore/CreditScoreHowItWorks'
import CreditScoreGetMore from '../components/ForCreditScore/CreditScoreGetMore'
import DontJustTake from '../components/UI/DontJustTake/index'
import ResourceCenter from '../components/UI/ResourceCenter'
import CSCFaq from '../components/ForCreditScoreClub/CSCFAQ'
import BlueCircle from '../assets/images/svg/blue-circle.svg'
import CSCBanner from '../components/ForCreditScoreClub/CSCBanner'
import { parseJson } from '../support'

const CreditScoreClub = ({ path }) => {

  const queriedStructuredDataConfigForCSC = useStaticQuery(graphql`
    query structuredDataConfigForCSCQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "credit-score-club" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(queriedStructuredDataConfigForCSC.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)

  return (
    <Layout>
      <Seo title="Credit Score Club" path={path} structuredData={structuredData} />
      <CreditScorePageWrapper>
        <div className="bg-circle">
          <BlueCircle />
        </div>
        <CSCHero />
      </CreditScorePageWrapper>
      <CSCBenefits />
      <CreditScoreHowItWorks />
      <CreditScoreGetMore />
      <hr className="solid" />
      <DontJustTake />
      <ResourceCenter type={'Credit Score'} />
      <CSCFaq />
      <CSCBanner />
    </Layout>
  )
}

const CreditScorePageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .bg-circle {
    position: absolute;
    left: auto;
    top: auto;
    right: 30vw;
    bottom: 37%;
    width: 6vw;
    height: auto;
    max-width: 90px;
  }
  @media screen and (max-width: 768px) {
    .bg-circle {
      left: auto;
      top: 40em;
      right: 40vw;
      bottom: auto;
      max-width: 340px;
      svg {
        width: 47vw;
        height: 47vw;
      }
    }
  }
`

export default CreditScoreClub
